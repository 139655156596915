import React, {Fragment} from 'react'
import { Link } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser';
import './Radio.css';
import bard from './img/barded3.png';
import Accordion from 'react-bootstrap/Accordion';
import { lazy, Suspense } from 'react';
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Articles from "../Articles";


class Radio extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            img_url: '',
            track_info: '',
            audio: new Audio("https://icecast.roomab.art:9000/test"),
            play_on: 0
        };
        this.getImg();
        this.tick();
        this.getTrackInfo();
        this.track_tick();
    }

    tick() {
        this.interval = setInterval(() => {
            this.getImg()
        }, 50000);
    }

    track_tick() {
        this.interval = setInterval(() => {
            this.getTrackInfo()
        }, 12000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    playAudio = () => {
        this.setState(state => ({play_on: 1}));
        this.state.audio.load();
        let playPromise = this.state.audio.play();

        if (playPromise !== undefined) {
            playPromise.then(_ => {
                // Automatic playback started!
                // Show playing UI.
                // We can now safely pause video...
                //this.state.audio.pause();
            })
                .catch(error => {
                    console.log(playPromise);
                    });
        }
        else {
            this.state.audio.load();
            this.state.audio.play();
        }
    }

    stopAudio = () => {
        this.state.audio.pause();
        //this.state.audio.currentTime = 0;
        this.setState(state => ({play_on: 0}));
    }

    getTrackInfo = () => {
        fetch('https://api.roomab.art/cur-track')
            .then(response => response.json())
            .then(data => {
                if (data.status === true) {
                    this.setState(state => ({track_info: data.title+' :: '+data.anons}))
                } else {
                    this.setState(state => ({track_info: ''}))
                }
            })
    }

    getImg = () => {
        fetch('https://api.roomab.art/rand-img')
            .then(response => response.json())
            .then(data => {
                if (data.status === true) {
                    this.setState(state => ({img_url: data.data}))
                } else {
                    this.setState(state => ({img_url: ''}))
                }
            })
    }

    render() {
        return <Fragment>
            <div className="wrap">
                <div className="container">
                    <div className="row row-offcanvas row-offcanvas-right sticky-top">
                        <div className=" container" id="magnitola">
                            <div className="line" id="l1">
                                <div className="marquee" id="radio_test">
                                    <span>{ReactHtmlParser(this.state.track_info)}</span></div>
                            </div>
                            <div className="latvia" id="lt">
                                <p className="text-center">
                                    <img rel="preload" src={bard} id="player" alt="Бард, который перевернул ЗИЛ"
                                         className="bard_img pic_bard"/>
                                </p>

                                <div id="buttons">
                                    <button id="play_btn" value="On"
                                            className={this.state.play_on ? "on_button active-button" : "off_button"}>
                                        <span
                                            className="glyphicon glyphicon-play"
                                            onClick={this.playAudio}></span>
                                    </button>
                                    <button id="stop_btn" value="stop"
                                            className={this.state.play_on ? "off_button active-button" : "on_button"}><span
                                        className="glyphicon glyphicon-pause" onClick={this.stopAudio}/></button>
                                    <button id="vk_btn" value className="off_button"><span className="fa fa-vk"/>
                                    </button>
                                    <Link to="https://t.me/radioRoomaBard" target="_blank" rel="noopener noreferrer">
                                        <button id="2_btn"
                                                value className="off_button"><span className="fa fa-telegram"></span>
                                        </button>
                                    </Link>
                                    <button id="3_btn" value className="off_button"><span className="glyphicon"/>
                                    </button>
                                    <button id="4_btn" value className="off_button"><span className="glyphicon"/>
                                    </button>
                                </div>
                                <p className="text-center"/>
                            </div>
                        </div>
                        {/*CSSTransition for Img in Login component*/}


                        <p className="bard">Лауреат Всесоюзного конкурса Исполнителей Звуковых Пыток и Наказаний,
                            Чемпион по скоростному спуску в мусоропроводе от разъярённых соседей  </p>
                        <h3 className="bard">Бард, который перевернул ЗИЛ</h3>
                        <p className="bard">
                        Он работает в стиле Агрессивный бард-поп и имеет немало поклонников и переклиников!
                        Люди с щепитильной натурой, хлипкими нервами и тонкой организацией
                        - все в сад! Он безумен ровно настолько, насколько слушающий его считает себя
                        здравомыслящим!
                        В его программе: Горький юмор и весёлая философия. История России в выдумках и причудах.
                        Вспомни всё и увидишь будущее!
                    </p>
                    <p className="bard">
                    Designed by <a href="https://roomab.art/">©Rock-N-ContRoll</a>
                            </p>
                        </div>
                    </div>

                </div>


        </Fragment>
    }
}


export default Radio;
