export const LANGUAGES = [
    { label: "English", code: "en" },
    { label: "Deutsch", code: "de" },
    { label: "Русский", code: "ru" },
    { label: "All", code: "all" },
];

export const PIC_FILES = [
    "http://88.85.67.159:8082//uploads/item_pics/so-ssvvecckkoii-v-trubkkkllleed9422196fc9dc153560caed187308ad2.jpg?cash=1701497210"
]

export const meta = {
    title: 'Radio',
    description: 'Radioroomaru',
    canonical: 'http://example.com/path/to/page',
    meta: {
        charset: 'utf-8',
        name: {
            viewport: 'react,meta,document,html,tags'
        }
    }
}

/*
<meta name="viewport" content="width=device-width, initial-scale=1">
    <meta name="twitter:image" content="http://radiorooma.ru/img/radiorooma_bard_treo.jpg">
    <meta name="twitter:site" content="Радио К○Мнɑ⊥а&copy;МеχØΣ">
    <meta name="twitter:card" content="Радио К○Мнɑ⊥а&copy;МеχØΣ">
    <meta name="twitter:title" content="Горький юмор и Весёлая философия">
    <meta name="twitter:description" content="Интернет радио номер одиннадцать!">

    <meta property="og:image" content="http://radiorooma.ru/img/radiorooma_bard_treo.jpg">
    <meta property="vk:image"  content="http://radiorooma.ru/img/radiorooma_bard_treo.jpg" />

    <meta property="og:site_name" content="К○Мнɑ⊥а&copy;МеχØΣ">
    <meta property="og:type" content="website">
    <meta property="og:title" content="Горький юмор и Весёлая философия">
    <meta property="og:url" content="http://radiorooma.ru">
    <meta property="og:description" content="Интернет радио номер одиннадцать!">
 */