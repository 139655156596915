import React, { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import DocumentMeta from 'react-document-meta';
import './App.css';
import Container from 'react-bootstrap/Container';
import User from "./User";
import Login from "./Login";
import Radio from "./Radio";
import {meta} from "./constants";

function App() {
  return (
      <Container fluid="md" >
          <BrowserRouter>
            <div className="App">
                <Routes>
                    <Route path="/" element={<Radio />} />
                </Routes>
            </div>
          </BrowserRouter>
      </Container>
  );
}

export default App;
