import React, {Fragment} from 'react'
import './Articles.css';
import Accordion from "react-bootstrap/Accordion";

class Articles extends React.Component {

    constructor(props) {
        super(props);

    }

    render() {
        return <Fragment>
            <Accordion>
                <Accordion.Item eventKey="-1">
                    <Accordion.Header>Кто не работает?</Accordion.Header>
                    <Accordion.Body>
                        Кто не работает, тот есть!<br/>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Бедный, ленивый, дурак</Accordion.Header>
                    <Accordion.Body>
                        Бедный - не всегда значит ленивый, бывает бедный и дурак<br/>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Пособник ЦРУ</Accordion.Header>
                    <Accordion.Body>
                        Куришь? Пьёшь? Соришь деньгами? -<br/>
                        Ты пособствуешь Обаме!<br/>
                        Что? Какую ещё трампу<br/>
                        Ну-ка встань-ка ты под лампу...<br/>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>Смелое решение</Accordion.Header>
                    <Accordion.Body>
                        Проблемы все свои решил<br/>
                        За несколько мгновений я:<br/>
                        В руке синицу задушил<br/>
                        И сбил ей в небе журавля!<br/><br/>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="-1">
                    <Accordion.Header>Кто не работает?</Accordion.Header>
                    <Accordion.Body>
                        Кто не работает, тот есть!<br/>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Бедный, ленивый, дурак</Accordion.Header>
                    <Accordion.Body>
                        Бедный - не всегда значит ленивый, бывает бедный и дурак<br/>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Пособник ЦРУ</Accordion.Header>
                    <Accordion.Body>
                        Куришь? Пьёшь? Соришь деньгами? -<br/>
                        Ты пособствуешь Обаме!<br/>
                        Что? Какую ещё трампу<br/>
                        Ну-ка встань-ка ты под лампу...<br/>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>Смелое решение</Accordion.Header>
                    <Accordion.Body>
                        Проблемы все свои решил<br/>
                        За несколько мгновений я:<br/>
                        В руке синицу задушил<br/>
                        И сбил ей в небе журавля!<br/><br/>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Бедный, ленивый, дурак</Accordion.Header>
                    <Accordion.Body>
                        Бедный - не всегда значит ленивый, бывает бедный и дурак<br/>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Пособник ЦРУ</Accordion.Header>
                    <Accordion.Body>
                        Куришь? Пьёшь? Соришь деньгами? -<br/>
                        Ты пособствуешь Обаме!<br/>
                        Что? Какую ещё трампу<br/>
                        Ну-ка встань-ка ты под лампу...<br/>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>Смелое решение</Accordion.Header>
                    <Accordion.Body>
                        Проблемы все свои решил<br/>
                        За несколько мгновений я:<br/>
                        В руке синицу задушил<br/>
                        И сбил ей в небе журавля!<br/><br/>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Бедный, ленивый, дурак</Accordion.Header>
                    <Accordion.Body>
                        Бедный - не всегда значит ленивый, бывает бедный и дурак<br/>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Пособник ЦРУ</Accordion.Header>
                    <Accordion.Body>
                        Куришь? Пьёшь? Соришь деньгами? -<br/>
                        Ты пособствуешь Обаме!<br/>
                        Что? Какую ещё трампу<br/>
                        Ну-ка встань-ка ты под лампу...<br/>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>Смелое решение</Accordion.Header>
                    <Accordion.Body>
                        Проблемы все свои решил<br/>
                        За несколько мгновений я:<br/>
                        В руке синицу задушил<br/>
                        И сбил ей в небе журавля!<br/><br/>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Бедный, ленивый, дурак</Accordion.Header>
                    <Accordion.Body>
                        Бедный - не всегда значит ленивый, бывает бедный и дурак<br/>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Пособник ЦРУ</Accordion.Header>
                    <Accordion.Body>
                        Куришь? Пьёшь? Соришь деньгами? -<br/>
                        Ты пособствуешь Обаме!<br/>
                        Что? Какую ещё трампу<br/>
                        Ну-ка встань-ка ты под лампу...<br/>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>Смелое решение</Accordion.Header>
                    <Accordion.Body>
                        Проблемы все свои решил<br/>
                        За несколько мгновений я:<br/>
                        В руке синицу задушил<br/>
                        И сбил ей в небе журавля!<br/><br/>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </Fragment>
    }
}


export default Articles;
