import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
    en: {
        translation: {
            title: "Multi-language app",
            label: "Select another language!",
            about: "About",
            home: "Home",
            login: "login",
            next: "Next",
            user: "User",
            logout: "Logout"
        },
    },
    ru: {
        translation: {
            title: "Тренер знаний",
            label: "Выбрать другой язык!",
            about: "О нас",
            home: "Домой",
            login: "Логин",
            next: "Дальше",
            user: "Пользователь",
            logout: "Выйти"
        },
    },
    de: {
        translation: {
            title: "Kenntnisse",
            label: "Wahlen Sie Andere Sprache",
            about: "Uber Uns",
            home: "Nach Hause",
            next: "Folge",
            user: "Nutzer",
            logout: "Gehe Aus"
        },
    },
    all: {
        translation: {
            title: "Multi-language app",
            label: "Select another language!",
            about: "About",
            home: "Home",
            login: "login"
        },
    },
};

i18n.use(initReactI18next)
    .use(Backend)
    .init({
        resources,
        lng: "en",
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    }).then(function(t) { t('key'); });

export default i18n;