import React, { Component, Fragment } from "react";
import i18n from "../i18n";
import {Translation} from "react-i18next";

class User extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {
                "gender": "",
                "lang": this.props.lang,
                "name": {
                    "title": this.props.name,
                    "first": "",
                    "last": ""
                },
                "location": {
                    "street": {
                        "number": 0,
                        "name": ""
                    },
                    "city": "",
                    "state": "",
                    "country": "",
                    "postcode": 0,
                    "coordinates": {
                        "latitude": "0.00",
                        "longitude": "0.00"
                    },
                    "timezone": {
                        "offset": "+0:00",
                        "description": ""
                    }
                }
                },
            error: null,
            loading: true,
        }
    }

    render() {
        console.log(this.state.user.name.title)
        return (
            <section>
                <Translation i18n={i18n}>
                    {
                        (t, { i18n }) =>
                            <p>
                                {t('user')} {this.state.user.name.title} {this.state.user.lang}
                            </p>
                    }
                </Translation>

            </section>
        );
    }
}

export default User;